import {Grid, Typography} from '@mui/material'
import {IRoster} from '../../response/Roster'
import {useRoster} from '../../hooks/useRoster'
import {LoadingSpinner} from '../LoadingSpinner'
import {RosterNotFound} from './RosterNotFound'
import {RosterNotAvailable} from './RosterNotAvailable'
import {useTranslation} from 'react-i18next'
import {AxiosError} from 'axios'
import {Link} from 'react-router-dom'
import {SearchTypeAction} from '../../common/declarations'

type SearchProps = {
  type: SearchTypeAction
  searchValue: string
  searchSubmitValue: boolean
  searchSubmit: React.Dispatch<React.SetStateAction<boolean>>
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export const RosterDetails: React.FC<SearchProps> = (props: SearchProps) => {
  const {t} = useTranslation()
  const rosterQuery = useRoster(props.searchValue, props.type, props.searchSubmitValue)

  const handleTabChangeToDispatch = () => {
    props.setTabIndex(2)
    props.searchSubmit(false)
  }

  if (!props.searchSubmitValue) return <Grid item></Grid>

  if (rosterQuery.isLoading) return <LoadingSpinner />

  if (rosterQuery.isSuccess && Object.keys(rosterQuery.data)?.length === 0) {
    return <RosterNotFound searchId={props.searchValue} />
  }

  if (rosterQuery.isError) {
    if ((rosterQuery.error as AxiosError)?.response?.status === 404) {
      return (
        <>
          <RosterNotFound searchId={props.searchValue} />
          <Link onClick={handleTabChangeToDispatch} to={''} data-test-id="pre-planning-link">
            {t('LandingPage.Search.DispatchLink')}
          </Link>
        </>
      )
    } else {
      return <RosterNotAvailable />
    }
  }

  const vehicleInfo = rosterQuery.data as IRoster
  const renderLoadingDetails = () => {
    if (vehicleInfo?.loadingDateTime && vehicleInfo?.loadingLocationAddress) {
      return (
        <>
          <h3>
            {t('LandingPage.LoadingTimeInformation')}{' '}
            {`${
              vehicleInfo.loadingDateTime
                ? new Date(vehicleInfo.loadingDateTime).toLocaleString()
                : ''
            }`}
          </h3>
          <Typography variant="caption">{t('LandingPage.LoadingInformation')}</Typography>
          <Typography>{vehicleInfo.loadingLocationName}</Typography>
          <Typography>{vehicleInfo.loadingLocationAddress}</Typography>
          <Typography>{vehicleInfo.loadingLocationPostcode}</Typography>
          <Typography>{vehicleInfo.loadingLocationCity}</Typography>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" data-test-id="driver-detail">
          {t('LandingPage.ResultInformation')}: {vehicleInfo?.driverFirstname}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12} data-test-id="pickup-detail">
        <h3>{t('LandingPage.PickUpInformation', {truckId: vehicleInfo.vehicleNumber})}</h3>
        <Typography>{vehicleInfo?.pickupLocationName}</Typography>
        <Typography>{vehicleInfo?.pickupAddress}</Typography>
        <Typography>{vehicleInfo?.pickupPostCode}</Typography>
        <Typography>{vehicleInfo?.pickupCity}</Typography>
      </Grid>
      <Grid item md={6} xs={12} data-test-id="loading-detail">
        {renderLoadingDetails()}
      </Grid>
    </Grid>
  )
}
